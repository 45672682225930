import {IMMValueObject, FirebaseValueObject} from "../../firebase/realtime-database/FirebaseValueObject";
import {AppTypedReference, IAppTypedReference} from "../cg/core/AppTypedReference";
import {CGIdentifierGenerator} from "../cg/util/CGIdentifierGenerator";
import {EAppReferenceType} from "../cg/core/AppReferenceType";
import {AppCluster} from "./AppCluster";
import {FirebaseMetaData} from "../../firebase/realtime-database/FirebaseMetaData";


export interface IAppParentChild extends IMMValueObject {

  parent: IAppTypedReference;
  child: IAppTypedReference;
  trashed: boolean;
  sequence: number;
}


export class AppParentChild extends FirebaseValueObject<IAppParentChild>{

  public static readonly SEQUENCE_NOT_SET = -1;

  public static readonly TYPE = EAppReferenceType.parent_child;

  parent: AppTypedReference;
  child: AppTypedReference;


  public static buildNew( parent: AppCluster, child: AppCluster, sequence = undefined ): AppParentChild {

    const answerId = CGIdentifierGenerator.generateId();

    if( 'number' !== typeof sequence ) {

      sequence = AppParentChild.SEQUENCE_NOT_SET;
    }

    const answerValue: IAppParentChild = {
      _self: {
        id: answerId,
        type: AppParentChild.TYPE
      },
      _meta: FirebaseMetaData.buildMeta(),
      parent: parent.value._self,
      child: child.value._self,
      trashed: false,
      sequence,
    }

    return new AppParentChild( answerValue );
  }

  protected onSetValue(value: IAppParentChild | null) {

    this.parent = null;
    this.child = null;

    super.onSetValue( value );

    if( value ) {

      // early 'parent_child' objects did not have a sequence
      if( 'number' !== typeof value.sequence ) {

        value.sequence = AppParentChild.SEQUENCE_NOT_SET;
      }


      this.parent = new AppTypedReference( value.parent );
      this.child = new AppTypedReference( value.child );
      this.child.sequence = this.value.sequence;
    }

  }


}
