
import firebase from 'firebase/compat/app';
import database = firebase.database;
import {IAppProperty, IAppProperty2, AppProperty} from "../../../model/AppProperty";
import {IFirebaseConnection} from "../../FirebaseConnection";


export class FirebaseProperty {

  private static getPath( propertyId: string ) {

    return `/properties/${propertyId}`;
  }

  static async readAll(firebaseConnection: IFirebaseConnection ): Promise<AppProperty[]> {

    const values: { [propertyKey: string]: IAppProperty; } = await firebaseConnection.database2.getValue( '/properties' );

    const answer: AppProperty[] = [];

    for( const propertyKey of Object.keys(values) ) {

      answer.push( new AppProperty( values[propertyKey], propertyKey ));
    }

    return answer;
  }


  static async readLegacyReference(fbDb: database.Database, propertyKey: string ): Promise<AppProperty|null> {

    const path = `/hotels/${propertyKey}`;

    const snapshot = await fbDb.ref( path ).once( 'value' );
    const value: IAppProperty = snapshot.val()[0];

    if ( value ) {

      return new AppProperty(value, propertyKey);
    }
    return null;
  }

  /**
   * @deprecated use 'readReferenceRedux' on this class
   */
  static async readReference(fbDb: database.Database, propertyKey: string ): Promise<AppProperty|null> {

    const path = this.getPath( propertyKey );

    const snapshot = await fbDb.ref( path ).once( 'value' );
    const value: IAppProperty = snapshot.val();

    if ( value ) {

      return new AppProperty(value, propertyKey);
    }
    return null;
  }

  static async readReferenceRedux( firebase: IFirebaseConnection, propertyKey: string ): Promise<AppProperty|null> {

    const path = this.getPath( propertyKey );
    const value: IAppProperty = await firebase.database2.getValue<IAppProperty>( path );

    if ( value ) {

      return new AppProperty(value, propertyKey);
    }
    return null;
  }

  static async writeReference( fbDb: database.Database, property: AppProperty  ): Promise<void> {

    const path = this.getPath( property.propertyKey );
    return fbDb.ref( path ).set( property.value );
  }

  static async writeValue( firebaseConnection: IFirebaseConnection, propertyKey: string, value: IAppProperty ): Promise<void> {

    const path = this.getPath( propertyKey );
    return firebaseConnection.database2.setValue( path, value );
 }

  static async writeValue2( firebaseConnection: IFirebaseConnection, propertyKey: string, value: IAppProperty2 ): Promise<void> {

    const path = this.getPath( propertyKey );
    return firebaseConnection.database2.setValue( path, value );
  }

}
