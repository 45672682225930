import {ValueObject} from "../../../model/ValueObject";
import {INocoReportFeature} from "./NocoReportFeature";
import {NocoDbProjectProxy} from "../../NocoDbProjectProxy";
import {IListingReponse} from "../../ListingReponse";
import {INocoProduct} from "../NocoProduct";
import {INocoReportFeatureSection, NocoReportFeatureSection} from "./NocoReportFeatureSection";
import {ILogger} from "../../../log/Logger";
import {LoggerFactory} from "../../../log/LoggerFactory";


export interface INocoClusterReportSection {
  Id: number;
  ClusterId: string;
  ReportSectionId: number;
}
export class NocoClusterReportSection extends ValueObject<INocoClusterReportSection> {


  protected onSetValue(value: INocoClusterReportSection) {
  }

  constructor( value: INocoClusterReportSection | null ) {
    super( value );
    if ( value ) {
      this.value = value;
    }
  }

}



export class NocoClusterReportSectionSet {

  private static _log: ILogger = LoggerFactory.build( 'NocoClusterReportSectionSet' );

  values: NocoClusterReportSection[] = [];
  valuesByClusterId: {[clusterId: string]: NocoClusterReportSection} = {};

  private static TABLE_NAME = "cluster_report_section";

  private static _getTableName( projectProxy: NocoDbProjectProxy ): string {

    if( projectProxy.proxy.isNocoDbVersion202Plus ) {
      return NocoClusterReportSectionSet.TABLE_NAME;
    }
    return 'ClusterReportSection';

  }

  public static async build( proxy: NocoDbProjectProxy ): Promise<NocoClusterReportSectionSet> {
    const tableName = this._getTableName(proxy);
    const value: IListingReponse<INocoClusterReportSection> = await proxy.getView<INocoClusterReportSection>( tableName );
    return new NocoClusterReportSectionSet( value );
  }

  public constructor( public value: IListingReponse<INocoClusterReportSection> ) {
    for( const rowValue of value.list ) {
      const reference = new NocoClusterReportSection( rowValue );
      this.values.push( reference );
      this.valuesByClusterId[rowValue.ClusterId] = reference;
    }
  }
}

