import {MMBaseException} from "../../../util/MMBaseException";
import {AppAnswerReview} from "../../app.back-office/AppAnswerReview";


export enum EAppReferenceType {

  answer_cluster = "answer_cluster",
  cluster = "cluster",
  parent_child = "parent_child",


  // Answer = "Ans",
  answer_boolean = "answer_boolean",
  answer_enum = "answer_enum",
  answer_float = "answer_float",
  answer_integer = "answer_integer",
  answer_line = "answer_line",
  answer_measurement = "answer_measurement",
  answer_photo = "answer_photo",
  answer_ternary = "answer_ternary",
  answer_text = "answer_text",

  // back-office ...
  answer_review = "answer_review",
  answer_review_cluster = "answer_review_cluster",

  none = "none",

  // ParentChild = "PaCh",

  QuestionCluster = "QuCl",

  Question = "Qstn",


  ScoreReview = "score_review",

}


export class EAppReferenceTypeHelper {


  public static lookup( type: string ): EAppReferenceType {

    throw MMBaseException.build( 'EAppReferenceTypeHelper', `'type' not found`, {
      type
    });

  }

}
