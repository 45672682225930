import {AppAnswer, IAppAnswer} from "./AppAnswer";
import {ValueObject} from "./ValueObject";
import { QuestionKey } from "./QuestionKey";
import {AppQuestionSet} from "./AppQuestionSet";
import {ILogger} from "../log/Logger";
import {LoggerFactory} from "../log/LoggerFactory";
import {AppTypedReference} from "./cg/core/AppTypedReference";
import {ICGAnswerCluster} from "./cg/core/CGAnswerCluster";
import {IScore} from "../hotel/model/accessibility/Score";
import {AppQuestion} from "./AppQuestion";
import {DefaultScoreCalculator} from "./score/DefaultScoreCalculator";
import {FirebaseMetaData} from "../firebase/realtime-database/FirebaseMetaData";
import {MMBaseException} from "../util/MMBaseException";


export class AppAnswerSet extends ValueObject<{ [key: QuestionKey]: IAppAnswer; }> {


  private _log: ILogger = LoggerFactory.build( 'AppAnswerSet' );

  answerByKey: { [key: QuestionKey]: AppAnswer } = {};
  answers: AppAnswer[] = [];

  private _completed  = false;
  private _score: IScore = null;

  // private _maximumScore: number|null = null;


  getAnswers( keys: QuestionKey[] ): AppAnswer[] {

    const response: AppAnswer[] = [];

    for( const key of keys ) {

      const question = this.questions.questionByKey[key];
      if( !question ) {
        this._log.warn( '!question', 'key', key );
        continue;
      }
      const answer = this.getAnswer( question );
      response.push( answer );
    }

    return response;
  }

  to() {

  }


  /**
   * Will create an answer if required
   * @param question
   */
  getAnswer( question: AppQuestion ): AppAnswer {


    const questionKey = question.value.key;
    let reference = this.answerByKey[ questionKey ];

    if( !reference ) {

      const answerValue = this.value[ questionKey ];
      if( answerValue ) {

        reference = new AppAnswer( question, answerValue );
      } else {

        reference = new AppAnswer( question );
        this.value[ questionKey ] = reference.value;
      }

      if( question.value.dependant ) {

        const dependantQuestion = this.questions.getQuestionById( question.value.dependant.questionKey );

        if( !dependantQuestion ) {

          console.warn( "!dependantQuestion", "question.value.dependant.questionKey: " + question.value.dependant.questionKey )
        } else {


          if( question.value.key === question.value.dependant.questionKey ) {
            throw MMBaseException.build( 'AppAnswerSet.getAnswer',
              'question.value.key === question.value.dependant.questionKey',
              {
                'questionKey': questionKey,
              });
          }

          try {

            const dependantAnswer = this.getAnswer( dependantQuestion );
            reference.dependant = dependantAnswer;

          } catch ( e ) {

            this._log.error( e, 'question', question, 'dependantQuestion', dependantQuestion );
          }

        }

      }

      this.answerByKey[ questionKey ] = reference;
    }

    return reference;
  }

  scrubMarkupTagsFromText( originalValue: string ) {

    let answer = originalValue;
    {
      const openingTag = /<([_a-zA-Z0-9]*)>/i; // https://regex101.com/r/NMrXnd/1
      let matches = originalValue.match( openingTag );
      if( matches ) {
        for( const match of matches ) {

          answer = answer.replace( `<${match}>`, `-${match}-` );
        }
      }
    }
    {
      const closingTag = /<\/([_a-zA-Z0-9]*)>/i; // https://regex101.com/r/tKJTNv/1
      let matches = originalValue.match( closingTag );
      if( matches ) {
        for( const match of matches ) {

          answer = answer.replace( `</${match}>`, `-${match}-` );
        }
      }
    }

    return answer;
  }

  // report-7152.remediation.md: Page 19: Lack of Input Validation(stored)
  scrubMarkupTagsFromTextAnswers() {

    for( const questionKey of Object.keys( this.answerByKey )) {
      const answer: AppAnswer = this.answerByKey[questionKey];
      if( answer.question.isText ) {
        if( answer.value.value && 'string' == typeof answer.value.value ) {

          answer.value.value = this.scrubMarkupTagsFromText( answer.value.value );
        }
      }
    }

  }

  willEditAnswers(): void {

    this._completed = null;
    this._score = null;
  }


  /**
   * @deprecated call 'answer.getScore()'
   */
  public getScoreForAnswer( answer: AppAnswer ): number {

    return DefaultScoreCalculator.INSTANCE.getScore( answer );
  }

  getScore( debug: boolean = false ): IScore|null {

    if( !this.isCompleted() ) {

      return null;
    }

    this._score = {

      numerator: 0,
      denominator: 0
    };

    for( const question of this.questions.questions ) {

      this._score.denominator += question.value.maximumScore;

      if( Number.isNaN( this._score.denominator )) {

        this._log.error( 'Number.isNaN( this._score.denominator )', 'question', question );
      }

      const answer = this.getAnswer(question);

      this._score.numerator += DefaultScoreCalculator.INSTANCE.getScore( answer );
      if( Number.isNaN( this._score.numerator )) {

        this._log.error( 'Number.isNaN( this._score.numerator )', 'question', question );
      }
    }

    return this._score;

  }

  isCompleted(): boolean {

    if( this._completed) {
      return true;
    }

    // fewer answers than questions
    if( Object.keys( this.value ).length < this.questions.questions.length ) {
      return false;
    }

    // there is a bug here, if questions are removed but new answers are introduced that have not been answered,
    // then 'isCompleted' will return true

    this._completed = true;
    return this._completed;
  }


  private _init() {

    // a hack to overcome '_init()' being called in the ctor but the field 'this.questions' has *NOT* been setup
    if( !this.questions ) {

      // AppAnswerSet._log.debug( '!this.questions', this.value );
      return;
    }
    this.answerByKey = {};
    this.answers = [];

    // AppAnswerSet._log.debug( 'this.value', this.value );

    for( const questionKey of Object.keys( this.value ) ) {

      // AppAnswerSet._log.debug( 'questionKey', questionKey );
      const question = this.questions.questionByKey[questionKey];
      if( !question ) {

        this._log.warn( '!question; question associated with answer not found', questionKey );
        continue;
      }

      const anAnswer = new AppAnswer( question, this.value[questionKey] );
      this.answers.push( anAnswer );
      this.answerByKey[questionKey] = anAnswer;
    }


    for( const answer of this.answers ) {

      if( answer.question.value.dependant ) {

        const dependantQuestionKey = answer.question.value.dependant.questionKey;
        const dependantAnswer = this.answerByKey[dependantQuestionKey];
        if( dependantAnswer ) {

          answer.dependant = dependantAnswer;
        }
      }
    }
  }

  /**
   * Shallow subset
   * @param keys
   */
  public getSubset( keys: QuestionKey[] ): AppAnswerSet {

    const answer = new AppAnswerSet(this.questions, {} );

    for( const key of keys ) {

      const questionAnswer = this.answerByKey[key];

      if( !questionAnswer) {
        this._log.warn( '!questionAnswer; answer not found', 'key', key );
        continue;
      }

      answer.value[key] = questionAnswer.value;
      answer.answers.push( questionAnswer );
      answer.answerByKey[key] = questionAnswer;
    }

    return answer;
  }


  public static buildFromAnswerCluster(questions: AppQuestionSet, value: ICGAnswerCluster ): AppAnswerSet {

    const answerSetValue: { [key: QuestionKey]: IAppAnswer; } = {};
    for( const referenceString of Object.keys( value.answers ) ) {

      const answerValue = value.answers[referenceString];
      const reference = AppTypedReference.build( referenceString );
      answerSetValue[reference.value.id] = answerValue.answer;
    }

    const response = new AppAnswerSet( questions, answerSetValue );
    return response;
  }


  public toAnswerCluster( identifier: AppTypedReference ): ICGAnswerCluster {

    const response: ICGAnswerCluster = {
      _meta: FirebaseMetaData.buildMeta(),
      _self: identifier.value,
      answers: {}
    };

    for( const answer of this.answers ) {

    }

    return response;
  }

  public static joinValues( values: { [key: QuestionKey]: IAppAnswer }[] ): { [key: QuestionKey]: IAppAnswer } {

    const answer: { [key: QuestionKey]: IAppAnswer } = {};

    for( const value of values) {

      for( const key of Object.keys( value )) {

        answer[key] = value[key];
      }
    }

    return answer;
  }


  protected onSetValue(value: { [key: QuestionKey]: IAppAnswer } | null) {

    this._init();
  }

  constructor( public questions: AppQuestionSet,
               value: { [key: QuestionKey]: IAppAnswer; } | null ) {

    super( value );
    this.value = value;
  }

}


