import {ValueObject} from "./ValueObject";
import {EProductType} from "./ProductType";




export interface IAppPropertyMetaData {

  // new fields added 2022-08-08 ...
  version?: number[];
}

export interface IAppBaseProperty {

  address: string|string[];
  country: string;
  email: string;
  name: string;

  // new fields added 2022-08-29 ...
  _meta?: IAppPropertyMetaData;
}

export interface IAppPropertyDebug {

  showQuestionIds?: boolean;
}

export interface IAppProperty extends IAppBaseProperty{

  // if _meta.version[0] === 2
  address: string|string[];
  country: string;
  email: string;
  name: string;
  orgType?: string;
  postCode: string;
  telephone: string;
  salesForceId?: string;
  website: string;

  // new fields added 2019-09-19 ...
  city?: string;
  numberOfBedrooms?: number;
  parentCompany?: string;
  starRating?: number;
  telephoneCountry?: string;

  // new fields added 2022-02-14 ...
  excludePhotos?: boolean;

  // new fields added 2022-08-29 ...
  _meta?: IAppPropertyMetaData;

  // new fields added 2023-10-17 ...
  clustered?: boolean;

  // new fields added 2024-04-12 ...
  debug?: IAppPropertyDebug;
}

// taken from `IMerckProperty` in `node.app.csv-to-json/src/model/MerckProperty.ts`
export interface IAppProperty2 extends IAppBaseProperty {

  country: string;
  name: string;
  address: string[];
  email: string;

  // new fields added 2022-09-12 ...
  _meta?: IAppPropertyMetaData;

  // new fields added 2023-10-17 ...
  clustered?: boolean;

  // new fields added 2024-03-14 ...
  productType?: EProductType;

}



export class AppProperty extends ValueObject<IAppProperty> {


  private _searchString: string = null;


  public static array = {

    sortById: ( properties: AppProperty[] ) => {

      properties.sort( (a,b) => {
        return a.propertyKey.localeCompare( b.propertyKey );
      })
    }
  }


  static buildId( country: string, name: string, company: string = null  ): string {

    if( company ) {

      company = company.toLowerCase();
    }
    country = country.toLowerCase();

    let answer = name;

    if( company ) {

      answer = `${company}-${country}-${name}`;
    } else {

      answer = `${country}-${name}`;
    }

    answer = answer.toLowerCase();
    answer = answer.replace( /\W/g, '-' );
    answer = answer.replace( /_/g, '-' ); // replace underscores

    answer = answer.replace( /--+/g, '-' ); // strip duplicate '-'s

    // https://regex101.com/r/VJ9CmZ/1
    answer = answer.replace( /^-+/, '' ); // strip leading '-'s

    // https://regex101.com/r/cELrPF/1
    answer = answer.replace( /-+$/, '' ); // strip trailing '-'s

    return answer;
  }

  get searchString(): string {

    if( null !== this._searchString ) {

      return this._searchString;
    }

    let searchString = '';

    if( this.value.name ) {

      searchString += this.value.name + '/';
    }
    if( this.value.address ) {

      searchString += this.value.address + '/';
    }

    if( this.value.salesForceId ) {

      searchString += this.value.salesForceId + '/';
    }

    this._searchString = searchString.toLowerCase();
    return this._searchString;
  }

  clone(): AppProperty {

    let value: IAppProperty = null;
    if( this.value ) {
      value = Object.assign( {}, this.value );
    }

    return new AppProperty( value, this.propertyKey );
  }

  protected onSetValue(value: IAppProperty | null) {

    if( value.salesForceId ) {

      delete value.salesForceId;
    }

    if( value.starRating ) {

      delete  value.starRating;
    }

  }

  constructor( value: IAppProperty|null = null,
               public propertyKey: string|null = null ) {

    super( value );
  }

}
