

<mat-sidenav-container aclass="sidenav-container">

  <mat-sidenav #sidenav aclass="sidenav" [mode]="'over'">

    <app-side-menu [sidenav]="sidenav">
    </app-side-menu>

  </mat-sidenav>


  <mat-sidenav-content>


    <div aclass="page-container">

      <mat-toolbar aclass="header-toolbar">

        <mat-toolbar-row >
          <button mat-button
                  (click)="sidenav.toggle()">
            <span class="material-icons">menu</span>
          </button>
          <span style="flex: 1 1 auto;"></span>
          <img src="assets/mobility-mojo/logo-and-text.white.png"
               aclass="header-toolbar-logo">
          <span style="flex: 1 1 auto;"></span>
          <div style="width: 64px; height: 10px;">
          </div>
        </mat-toolbar-row>

      </mat-toolbar>


      <div aclass="page-content">

        <app-session-checker
          *ngIf="!sessionContext.sessionIsReady">
        </app-session-checker>


        <div *ngIf="pageAnswers">
          <aspect-header
                         [maxStep]="pageDefinitions.value.length"
                         [currentStep]="this.activeIndex+1"
                         [sectionTitle]="pageDefinitions.value[this.activeIndex].value.title"
          >
          </aspect-header>

          <app-question-list
            [answers]="pageAnswers"
            [property]="property"
          >
          </app-question-list>

        </div>


      </div>


      <mat-toolbar aclass="footer-toolbar">


        <mat-toolbar-row>

          <button mat-button disabled (click)="onPrevious()" [disabled]="isSaving">
            <mat-icon>chevron_left</mat-icon>
            PREVIOUS
          </button>

          <span style="flex: 1 1 auto;"></span>


          <button mat-button (click)="onNext()" [disabled]="isSaving">
            NEXT
            &nbsp;
            <mat-icon>chevron_right</mat-icon>
          </button>
        </mat-toolbar-row>


      </mat-toolbar>



    </div>


  </mat-sidenav-content>

</mat-sidenav-container>


