

<mat-sidenav-container aclass="sidenav-container">

  <mat-sidenav #sidenav aclass="sidenav" [mode]="'over'">

    <app-side-menu [sidenav]="sidenav">
    </app-side-menu>

  </mat-sidenav>

  <mat-sidenav-content>


    <div aclass="page-container">

      <mat-toolbar aclass="header-toolbar">

        <mat-toolbar-row >
          <button mat-button
                  (click)="sidenav.toggle()">
            <span class="material-icons">menu</span>
          </button>
          <span style="flex: 1 1 auto;"></span>
          <img src="assets/mobility-mojo/logo-and-text.white.png"
               aclass="header-toolbar-logo">
          <span style="flex: 1 1 auto;"></span>
          <div style="width: 64px; height: 10px;">
          </div>
        </mat-toolbar-row>

      </mat-toolbar>

      <div aclass="page-content">


        <app-session-checker
          *ngIf="!sessionContext.sessionIsReady">
        </app-session-checker>

        <div *ngIf="sessionContext.sessionIsReady && !sessionContext.propertyContext">

          <div fxLayout="column" fxLayoutGap="10px">
            <div fxLayout="row" fxLayoutAlign="start center">
              <div fxFlex cclass="instructions">
                You have no properties associated with your account.
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" style="padding-bottom: 12px;">
              <div fxFlex cclass="instructions">
                If you believe this is a mistake, please contact the <strong>Mobility Mojo</strong> staff.
              </div>
            </div>
          </div>
        </div>


        <div *ngIf="sessionContext.sessionIsReady && sessionContext.propertyContext">



          <div fxLayout="column" fxLayoutGap="10px">
            <div fxLayout="row" fxLayoutAlign="start center">
              <div fxFlex cclass="instructions">
                Evaluating:
                <strong>
                  {{sessionContext.propertyContext.property.value.name}}
                </strong>
                <span style="display: none">
                    propertyKey: {{sessionContext.propertyContext.property.propertyKey}}
                  </span>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" style="padding-bottom: 12px;">
              <div fxFlex cclass="instructions">
                <strong>
                  To complete your
                  <span [ngSwitch]="productType">
                  <span *ngSwitchCase="productTypes.brandHome">brand home</span>
                  <span *ngSwitchCase="productTypes.facilities">facilities</span>
                  <span *ngSwitchCase="productTypes.manufacturing">manufacturing</span>
                </span>
                  evaluation, click each section and answer the questions
                </strong>
              </div>
            </div>
          </div>


          <div *ngIf="sessionContext.sessionIsReady && initCompleted">




          <div *ngFor="let child of hierarchyRoot.children; let $index = index" class="cluster-container">

            <app-cluster [childCluster]="child.cluster"
                         [clusterNode]="child"
                         (deleteCluster)="onDeleteCluster(child, $event )"
                         (addCluster)="onAddSubSection(child)"
                         [sectionIndex]="$index+1"
                         [evaluationState]="evaluationState"
            >
            </app-cluster>

          </div>


            <div style=" margin-bottom: 16px;">

              <mat-card style="margin-bottom: 16px;">

                <mat-card-content>

                  <button cclass="add-section" class="sub-section add-sub-section" mat-raised-button
                          *ngIf="canEditClusters"
                          (click)="onAddSection()"
                          [disabled]="evaluationState==evaluationStates.submitting||evaluationState==evaluationStates.submitted"
                  >
                    <div cclass="add-section">
                      <mat-icon cclass="add-section-icon" aria-hidden="false" aria-label="Add">add</mat-icon>
                      <span cclass="add-section-title">Add Section</span>
                      <span cclass="add-section-filler"></span>
                    </div>
                  </button>

                  <div>
                    <button cclass="add-section" class="sub-section add-sub-section" mat-raised-button
                            (click)="onEvaluationCompleted()"
                            [disabled]="null!=reasonSubmitDisabled()"
                    >
                      <div cclass="add-section">
                        <mat-icon cclass="add-section-icon" aria-hidden="false" aria-label="Add">send</mat-icon>
                        <span cclass="add-section-title">{{submitEvaluationText()}}</span>
                        <span cclass="add-section-filler"></span>
                      </div>
                    </button>

                  </div>

                </mat-card-content>

              </mat-card>

            </div>

          </div>

        </div>

        <ng-fireworks #fireworks="ngFireworks"
                      [options]="options"
                      class="fireworks"
                      *ngIf="fireworksEnabled"
        >
        </ng-fireworks>

      </div>

      <mat-toolbar aclass="footer-toolbar">

        <mat-toolbar-row>
        </mat-toolbar-row>

      </mat-toolbar>


    </div>


  </mat-sidenav-content>

</mat-sidenav-container>


