import {NocoCluster, NocoClusterId} from "../../nocodb/model/NocoCluster";
import {ValueObject} from "../ValueObject";
import {IMMValueObject, FirebaseValueObject} from "../../firebase/realtime-database/FirebaseValueObject";
import {EAppReferenceType} from "../cg/core/AppReferenceType";
import {AppTypedReference} from "../cg/core/AppTypedReference";
import {AppCluster, IAppCluster} from "./AppCluster";
import {CGIdentifierGenerator} from "../cg/util/CGIdentifierGenerator";
import {FirebaseMetaData} from "../../firebase/realtime-database/FirebaseMetaData";


export interface IAppClusterType extends IMMValueObject {

  clusterId: NocoClusterId;
  name: string;

  // subClusters: ICGAppClusterDefinition[];
}

export class AppClusterType extends ValueObject<IAppClusterType>{

  public iconName: string|null = null;
  public optional: boolean = false;

  protected onSetValue(value: IAppClusterType | null) {
  }

  buildAppCluster( name: string|null = null ): AppCluster {

    if( !name ) {

      name = this.value.name;
    }
    const answerId = CGIdentifierGenerator.generateId();

    const answerValue: IAppCluster = {
      _meta: FirebaseMetaData.buildMeta(),
      _self: {
        id: answerId,
        type: AppCluster.TYPE
      },
      completed: false,
      clusterTypeId: this.value.clusterId,
      name,
      responsibility: 'landlord',
      core: false,
      optional: false,
    }

    return new AppCluster( answerValue );
  }


  public static build( nocoCluster: NocoCluster ): AppClusterType {

    const selfReference = new AppTypedReference( {
      type: EAppReferenceType.QuestionCluster,
      id: nocoCluster.value.Id
    });

    const answerValue: IAppClusterType = {
      _meta: FirebaseMetaData.buildMeta(),
      _self: selfReference.value,
      name: nocoCluster.value.Name,
      clusterId: nocoCluster.value.Id
    }

    return new AppClusterType( answerValue, nocoCluster );

  }

  constructor( value: IAppClusterType,
               public nocoCluster: NocoCluster ) {

    super(value);

    if (nocoCluster.value.Icon) {
      this.iconName = nocoCluster.value.Icon;
    }
    if (nocoCluster.value.Optional) {
      this.optional = nocoCluster.value.Optional;
    }
  }

}
