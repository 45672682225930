import {IFirebaseConnection} from "../../FirebaseConnection";
import {ILogger} from "../../../log/Logger";
import {LoggerFactory} from "../../../log/LoggerFactory";
import {IMMValueObject, FirebaseValueObject} from "../FirebaseValueObject";


export class FirebaseMMValueObject {

  private _log: ILogger = LoggerFactory.build( 'FirebaseMMValueObject' );


  public static addUpdate( firebaseConnection: IFirebaseConnection, target: FirebaseValueObject<IMMValueObject> ) {

    let userUid = "no-user";
    if( firebaseConnection.user ) {

      userUid = firebaseConnection.user.uid;
    }


    target.value._meta.updates.push( {
      who: userUid,
      when: new Date().getTime()
    } );

  }

}
