

import {IJavaScriptLibEnvironment} from "./JavaScriptLibEnvironment";


export const javaScriptLibEnvironment: IJavaScriptLibEnvironment = {

  logging: {
    execSystem: "browser.app.facilites-evaluation-tool",
    environmentId: "facilities-test-8c1f9"
  }

}
