



<mat-slide-toggle
  [(ngModel)]="userPropertyView.include"
>
</mat-slide-toggle>

&nbsp;
<code>{{userPropertyView.property.propertyKey}}</code>: {{userPropertyView.property.value.name}}







