import {ValueObject} from "../model/ValueObject";
import {NocoDbProjectProxy} from "./NocoDbProjectProxy";
import {ILogger} from "../log/Logger";
import {LoggerFactory} from "../log/LoggerFactory";


export interface IColumnInformation {

  id: string;
  title: string;
  data_type: string;
  primary_key: string;
  column_type: string;
}

export interface ITableInformation {

  id: string;
  table_name: string;
}

export interface ITableListing {

  list: ITableInformation[];
}


export class TableListing extends ValueObject<ITableListing> {


  private _log: ILogger = LoggerFactory.build( 'TableListing' );


  protected onSetValue(value: ITableListing | null) {
  }


  public tableExists( target: string ): boolean {

    for( const candidate of this.value.list ) {

      if( target === candidate.table_name ) {

        this._log.info( 'target === candidate.table_name', 'target', target );
        return true;
      }
    }

    this._log.info( 'target not found', 'target', target );
    return false;

  }


  constructor( value: ITableListing) {
    super( value );
  }
}

