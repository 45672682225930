// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

import {IProductEnvironment} from "./FacilitiesEvaluationEnvironment";
import {EProductType} from "../javascript.lib.mojo-base/model/ProductType";
import {TemplateProduct} from "../model.product/TemplateProduct";
import {FacilityAccessibilityProduct4} from "../product.facility/model/FacilityAccessibilityProduct4";

export const environment: IProductEnvironment = {

  name: 'Failte Ireland: Food and Beverage',
  filename: 'environment.fnb-70768473.ts',

  isDevelopment: false,
  isProduction: true,
  isTest: false,


  authenticationConfig: {

    emailLink: false,
    // microsoft: {
    //   clientId: "98e2543e-b602-4771-a509-66d0bfcfcf22",
    //   tenantId: "3872f7c4-cb94-413b-93f5-874b9feb4674",
    //   companyName: "Mobility Mojo",
    // },
    textMessage: true,
    usernamePassword: true,
    google: false
  },

  firebaseConfig: {
    apiKey: "AIzaSyC405PJr2KfFr2vuz2ymCza8TFsENBuU-E",
    authDomain: "facilities-70768473.firebaseapp.com",
    databaseURL: "https://facilities-70768473-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "facilities-70768473",
    storageBucket: "facilities-70768473.appspot.com",
    messagingSenderId: "903513622478",
    appId: "1:903513622478:web:3c03c62c02b1f05bf0cd9f"
  },

  // curl -iLS https://us-central1-$_TARGET_ENV.cloudfunctions.net/public/functions/info
  cloudFunctionsUrl: 'https://us-central1-facilities-70768473.cloudfunctions.net',

  nocoDbConfig: {
    // httpServer: 'http://localhost:8080',
    // httpServer: 'http://spot.local:8080',
    httpServer: 'https://mobilitymojo-spot.com',
    user: 'test@mobilitymojo.com',
    password: 'test@mobilitymojo.com',
    authorization: 'Basic bW9iaWxpdHk6bW9qbw==',
    projectTitle: '2024 - Grosvenor and The Crown Estate',
  },


  productConfig: {

    // product:  ProductHelper.cloneProduct( new FacilityAccessibilityProduct(), true )
    // product:  BankingAccessibilityProduct.INSTANCE,
    // product:  TemplateProduct.INSTANCE,
    // product:  FacilityAccessibilityProduct4.INSTANCE,

    // firebaseAnswersRoot: '/answers-x/banking',
    // productType: EProductType.banking,
    // product: TemplateProduct.INSTANCE,

    firebaseAnswersRoot: '/answers-x/food-and-beverage',
    product:  FacilityAccessibilityProduct4.INSTANCE,
    productType: EProductType.foodAndBeverage,
    canCommentOnClusters: true,
    canEditClusters: false,
    clusterSupport: true,

  },


  preview: {

    fireworksEnabled: true,
  },


};
