import {AppAspectAnswers} from "../javascript.lib.mojo-base/model/app.aspect/AppAspectAnswers";
import {PwaApplicationContextProvider} from "../service.pwa-application-context/pwa-application-context";
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {AppProperty} from "../javascript.lib.mojo-base/model/AppProperty";
import {IScore} from "../javascript.lib.mojo-base/hotel/model/accessibility/Score";
import {AngularFirebaseAnswers} from "../browser.lib.evaluation-tool/firebase/realtime-database/answers-x/AngularFirebaseAnswers";
import {environment} from "../environments/environment";
import {IProduct} from "../model.product/IProduct";
import {ProductHelper} from "../model.product/ProductHelper";
import {LoggerFactory} from "../javascript.lib.mojo-base/log/LoggerFactory";
import {FirebaseConnectionService} from "../browser.lib.evaluation-tool/service.firebase-connection/FirebaseConnectionService";
import {FirebaseEvaluationState} from "../javascript.lib.mojo-base/firebase/evaluation/FirebaseEvaluationState";
import {EvaluationStatus} from "../javascript.lib.mojo-base/model/evaluation/EvaluationStatus";

export class PwaPropertyContext {

  private static log = LoggerFactory.build( 'PwaPropertyContext' );

  public propertyKey: string;
  public aspectAnswers: AppAspectAnswers[] = [];

  public previouslyCompleted: boolean|null = null;

  public completedPageHasBeenShown: boolean = false;
  public showCongratulationsText: boolean = true;

  private _completed: boolean|null = null;
  private _score: IScore = null;

  public evaluationStatus: EvaluationStatus = new EvaluationStatus( null );

  willEditAnswers(): void {

    if( !this._completed ) {
      this._completed = null;
    }
    this._score = null;
  }

  isEvaluationCompleted(): boolean|null {

    if( null !== this._completed ) {
      return this._completed;
    }

    let optionalSections = 0;
    let optionalSectionsCompleted = 0;
    for(const aspect of this.aspectAnswers) {
      if (aspect.isOptional()) {
        optionalSections++;
        if (aspect.isCompleted()) {
          optionalSectionsCompleted++;
        }
      } else if(!aspect.isCompleted()) {
        // a non-optional section is incomplete so the
        // evaluation is not complete
        this._completed = false;
        return this._completed;
      }
    }

    // all non-optional sections are complete, and if we have any optional sections at least one must be complete
    this._completed
      = (optionalSections > 0 && optionalSectionsCompleted > 0)
        ? true
        : false;
    return this._completed;
  }

  getScore(): IScore|null {

    if( this._score ) {
      return this._score;
    }

    if( !this.isEvaluationCompleted() ) {
      return null;
    }

    const score: IScore = {
      numerator: 0,
      denominator: 0
    }

    for( const aspect of this.aspectAnswers ) {

      const aspectScore = aspect.getScore();

      if( !aspectScore ) {
        continue;
        // return null;
      }

      score.numerator += aspectScore.numerator;
      score.denominator += aspectScore.denominator;
    }

    this._score = score;
    return this._score;
  }

  private static _getProduct( property: AppProperty ): IProduct {

    if( property.value.excludePhotos ) {
      PwaPropertyContext.log.debug( 'property.value.excludePhotos' );
      return ProductHelper.cloneProduct( environment.productConfig.product, true );
    }

    return environment.productConfig.product;
  }

  public static async build( applicationContext: PwaApplicationContextProvider,
                             property: AppProperty,
                             firebaseConnection: FirebaseConnectionService ): Promise<PwaPropertyContext> {

    const product = PwaPropertyContext._getProduct( property );
    const answer = new PwaPropertyContext( property, product );

    if(!property.value.clustered) {

      answer.previouslyCompleted = true;

      for(const aspectQuestions of product.evaluationSections) {

        let value = await AngularFirebaseAnswers.readValueXByKeyRedux( firebaseConnection, property.propertyKey, aspectQuestions );
        if(!value) {
          value = {};
        }

        const aspectAnswers: AppAspectAnswers = new AppAspectAnswers( value, aspectQuestions );
        answer.aspectAnswers.push( aspectAnswers );

        if (!aspectAnswers.isCompleted() ) {
          answer.previouslyCompleted = false;
        }

        let evaluationStatus = await FirebaseEvaluationState.read( firebaseConnection, environment.productConfig.productType, property.propertyKey );

        if(evaluationStatus) {
          answer.evaluationStatus = evaluationStatus;
        } else {
          answer.evaluationStatus = new EvaluationStatus( {} );
        }
      }
    }

    return answer;
  }

  private constructor( public property: AppProperty,
                       public product: IProduct ) {
    this.propertyKey = property.propertyKey;
  }
}
