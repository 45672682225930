

import {ValueObject} from "../../model/ValueObject";
import {NocoDbProjectProxy} from "../NocoDbProjectProxy";
import {IListingReponse} from "../ListingReponse";
import {NocoProduct, NocoProductId} from "./NocoProduct";
import {EAppReferenceType} from "../../model/cg/core/AppReferenceType";


export type NocoClusterId = string;


export interface INocoCluster {

  Id: NocoClusterId;
  Name: string;
  TempEvaluationSectionId: number;
  TempProductId: number;
  Icon?: string;
  Optional?: boolean;

  // set at runtime if necessary ...
  ProductId: NocoProductId;
}


export class NocoCluster extends ValueObject<INocoCluster> {

  protected onSetValue(value: INocoCluster | null) {
    if( value.TempProductId ) {
      value.ProductId = value.TempProductId;
    }
  }

  constructor( value: INocoCluster | null ) {
    super( value );
    if ( value ) {
      this.value = value;
    }
  }
}

export class NocoClusterSet {

  values: NocoCluster[] = [];
  valuesById: {[id: NocoClusterId]: NocoCluster} = {};


  private static _getTableName( projectProxy: NocoDbProjectProxy ): string {

    if( projectProxy.proxy.isNocoDbVersion202Plus ) {
      return 'cluster';
    }
    return 'Cluster';

  }


  public static async getValue( proxy: NocoDbProjectProxy ): Promise<IListingReponse<INocoCluster>> {

    const tableName = this._getTableName( proxy );
    return proxy.getView<INocoCluster>( tableName );
  }



  public getSubset( productId: NocoProductId ) {

    const subsetValue: INocoCluster[] = [];

    for( const candidate of this.values ) {

      if( candidate.value.ProductId === productId ) {

        subsetValue.push( candidate.value );
      }
    }

    return new NocoClusterSet( subsetValue );
  }


  public static async build( proxy: NocoDbProjectProxy ): Promise<NocoClusterSet> {

    const tableName = this._getTableName( proxy );
    const value: IListingReponse<INocoCluster> = await proxy.getView<INocoCluster>( tableName );
    return new NocoClusterSet( value.list );
  }

  // list
  // public constructor( public value: IListingReponse<INocoCluster> ) {
  public constructor( public list: INocoCluster[] ) {

    for( const rowValue of list ) {

      const reference = new NocoCluster( rowValue );
      this.values.push( reference );
      this.valuesById[rowValue.Id] = reference;
    }
  }


}

