import {IFirebaseConnection} from "../../FirebaseConnection";
import {AppCluster, IAppCluster} from "../../../model/app.cluster/AppCluster";
import {AppTypedReference, AppTypedReferenceString} from "../../../model/cg/core/AppTypedReference";
import {AppClusterSet} from "../../../model/app.cluster/AppClusterSet";
import {EAppReferenceType} from "../../../model/cg/core/AppReferenceType";
import {environment} from "../../../../environments/environment";
import {FirebaseMMValueObject} from "./FirebaseMMValueObject";

export class  FirebaseCluster {

  private static _getPath( propertyKey: string, clusterReference: AppTypedReferenceString|null ): string {

    const productKey = environment.productConfig.productType;

    if( clusterReference ) {

      return `/answer-clusters/${productKey}/${propertyKey}/${EAppReferenceType.cluster}/${clusterReference}`;
    }
    const answer = `/answer-clusters/${productKey}/${propertyKey}/${EAppReferenceType.cluster}`;
    return answer;
  }

  static async writeReference( firebaseConnection: IFirebaseConnection, propertyKey: string, appCluster: AppCluster ): Promise<void> {

    const path = this._getPath( propertyKey, appCluster._self.toString );
    FirebaseMMValueObject.addUpdate( firebaseConnection, appCluster );
    return firebaseConnection.database2.setValue( path, appCluster.value );
  }

  static async readReference( firebaseConnection: IFirebaseConnection, propertyKey: string, clusterReference: AppTypedReferenceString ): Promise<AppCluster>|null {

    const path = this._getPath( propertyKey, clusterReference );
    const value = await firebaseConnection.database2.getValue<IAppCluster|null>( path );

    if( !value ) {

      return null;
    }

    return new AppCluster( value );
  }

  static async readReferences( firebaseConnection: IFirebaseConnection, propertyKey: string ): Promise<AppClusterSet> {

    const path = this._getPath( propertyKey, null );
    const values = await firebaseConnection.database2.getValue<{ [key: AppTypedReferenceString]: IAppCluster; }|null>( path );

    if( !values ) {

      return new AppClusterSet({} );
    }
    return new AppClusterSet( values );
  }


}
